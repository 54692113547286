/* eslint-disable */
/* tslint:disable */
/* Do not edit - generated by swagger-typescript-api */

import {
  Condition,
  ProductListItemsIdentificationResponseCollectionResponse,
  ProductListItemsRequest,
  ProductOverviewResponseCollectionResponse,
  ProductResponseCollectionResponse,
  SaleMonthlyOverviewResponseCollectionResponse,
  Sorting,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';
export class Products<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;
  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }
  /**
   * No description
   *
   * @tags Products
   * @name ProductsList
   * @request GET:/Products
   * @response `200` `ProductResponseCollectionResponse` Success
   */
  productsList = (
    query?: {
      /** @format int32 */
      skip?: number;
      /** @format int32 */
      top?: number;
      inlineCount?: boolean;
      search?: string;
      sortings?: Sorting[];
      conditions?: Condition[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<ProductResponseCollectionResponse, any>({
      path: `/Products`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Products
   * @name GetProductMonthlySales
   * @request GET:/Products/{id}/MonthlySales
   * @deprecated
   * @response `200` `SaleMonthlyOverviewResponseCollectionResponse` Success
   */
  getProductMonthlySales = (id: number, params: RequestParams = {}) =>
    this.http.request<SaleMonthlyOverviewResponseCollectionResponse, any>({
      path: `/Products/${id}/MonthlySales`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Products
   * @name GetProductMonthlySalesWithMonthsRange
   * @request GET:/Products/{id}/MonthlySales/MonthsRange/{monthsRange}
   * @response `200` `SaleMonthlyOverviewResponseCollectionResponse` Success
   */
  getProductMonthlySalesWithMonthsRange = (
    id: number,
    monthsRange: number,
    params: RequestParams = {},
  ) =>
    this.http.request<SaleMonthlyOverviewResponseCollectionResponse, any>({
      path: `/Products/${id}/MonthlySales/MonthsRange/${monthsRange}`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Products
   * @name GetRegionProductMonthlySales
   * @request GET:/Products/{id}/Regions/{regionId}/MonthlySales
   * @deprecated
   * @response `200` `SaleMonthlyOverviewResponseCollectionResponse` Success
   */
  getRegionProductMonthlySales = (id: number, regionId: number, params: RequestParams = {}) =>
    this.http.request<SaleMonthlyOverviewResponseCollectionResponse, any>({
      path: `/Products/${id}/Regions/${regionId}/MonthlySales`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Products
   * @name GetRegionProductMonthlySalesWithMonthsRange
   * @request GET:/Products/{id}/Regions/{regionId}/MonthlySales/MonthsRange/{monthsRange}
   * @response `200` `SaleMonthlyOverviewResponseCollectionResponse` Success
   */
  getRegionProductMonthlySalesWithMonthsRange = (
    id: number,
    regionId: number,
    monthsRange: number,
    params: RequestParams = {},
  ) =>
    this.http.request<SaleMonthlyOverviewResponseCollectionResponse, any>({
      path: `/Products/${id}/Regions/${regionId}/MonthlySales/MonthsRange/${monthsRange}`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Products
   * @name ProductOverviews
   * @request GET:/Products/{id}/Overviews
   * @response `200` `ProductOverviewResponseCollectionResponse` Success
   */
  productOverviews = (id: number, params: RequestParams = {}) =>
    this.http.request<ProductOverviewResponseCollectionResponse, any>({
      path: `/Products/${id}/Overviews`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Products
   * @name IdentificationProduct
   * @request POST:/Products/Identification
   * @response `200` `ProductListItemsIdentificationResponseCollectionResponse` Success
   */
  identificationProduct = (data: ProductListItemsRequest, params: RequestParams = {}) =>
    this.http.request<ProductListItemsIdentificationResponseCollectionResponse, any>({
      path: `/Products/Identification`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
