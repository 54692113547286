/**
 * Format a number using specified number of significant digits.
 *
 * If the number of requested significant digits is less than the
 * precision of the input value, the output value is rounded according
 * to regular mathematical rules (<0.5 down, >=0.5 up).
 *
 * If the number of requested significant digits is more than the
 * precision of the input value, the output is padded with zeroes to
 * achieve the desired precision.
 *
 * Thousands are separated by "separator" (space by default).
 *
 * @param value Input value.
 * @param decimalCount Number of significant digits.
 * @param separator
 * @return Formatted number with specified number of significant digits.
 */
export function formatNumber(value: number | string, decimalCount = 0, separator = ' '): string {
  return (typeof value === 'string' ? parseFloat(value) : value)
    .toFixed(decimalCount)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1${separator}`);
}

/**
 * This function takes an input value (number or string) and a decimal count, and outputs a formatted
 * string that represents the amount of money in an easily-readable format.
 *
 * It uses the `formatNumber` helper function to format the input number to the specified decimal
 * places and to include thousand separators.
 *
 * If the input value represents a billion or more, the output string will use 'B'
 * character as an abbreviation, and the input value will be divided by 1,000,000,000.
 *
 * If the input value represents a million or more but less than a billion,
 * the output string will use 'M' character as an abbreviation, and the input
 * value will be divided by 1,000,000.
 *
 * If the input value represents a thousand or more but less than a million,
 * the output string will use 'k' character as an abbreviation, and the input
 * value will be divided by 1,000.
 *
 * @param value - The input value to be formatted.
 * @param decimalCount - The number of decimal places to be used in the formatting. The default is 0.
 * @returns The formatted monetary string.
 */
export function shortenNumber(value: number | string, decimalCount = 1): string {
  let num = typeof value === 'string' ? parseFloat(value) : value;
  let symbol = '';

  // Define billion, million & thousand units
  if (num >= 1000000000) {
    num = num / 1000000000;
    symbol = 'B';
  } else if (num >= 1000000) {
    num = num / 1000000;
    symbol = 'M';
  } else if (num >= 1000) {
    num = num / 1000;
    symbol = 'k';
  }

  if (Math.ceil(num) === Math.floor(num)) {
    decimalCount = 0;
  }

  return formatNumber(num, decimalCount, ',') + symbol;
}

export const formatMonetaryValue = (
  currency: string,
  value: number | string | null | undefined,
  decimalCount = 0,
): string => {
  return `${formatNumber(value ?? 0, decimalCount)} ${currency}`;
};

/**
 * Format given number as a percentage (adds % sign directly after the number)
 * @param percentageValue
 * @param decimalCount
 */
export const formatPercentage = (percentageValue: number | string, decimalCount = 0): string => {
  return `${formatNumber(percentageValue, decimalCount)}%`;
};
