/* eslint-disable */
/* tslint:disable */
/* Do not edit - generated by swagger-typescript-api */

import {
  CalculationResponseCollectionResponse,
  Condition,
  EntityListCreateRequest,
  EntityListImportItemResponseCollectionResponse,
  EntityListImportItemsRequest,
  EntityListImportRequest,
  EntityListImportResponseDataResponse,
  EntityListImportSummaryResponseCollectionResponse,
  EntityListImportVerificationRequest,
  EntityListItemResponseCollectionResponse,
  EntityListResponseCollectionResponse,
  EntityListResponseDataResponse,
  EntityListUpdateRequest,
  Sorting,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';
export class EntityLists<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;
  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }
  /**
   * No description
   *
   * @tags EntityLists
   * @name EntityListsList
   * @request GET:/EntityLists
   * @response `200` `EntityListResponseCollectionResponse` Success
   */
  entityListsList = (
    query?: {
      /** @format int32 */
      skip?: number;
      /** @format int32 */
      top?: number;
      inlineCount?: boolean;
      search?: string;
      sortings?: Sorting[];
      conditions?: Condition[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<EntityListResponseCollectionResponse, any>({
      path: `/EntityLists`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags EntityLists
   * @name CreateEntityList
   * @request POST:/EntityLists
   * @response `200` `EntityListResponseDataResponse` Success
   */
  createEntityList = (data: EntityListCreateRequest, params: RequestParams = {}) =>
    this.http.request<EntityListResponseDataResponse, any>({
      path: `/EntityLists`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags EntityLists
   * @name UpdateEntityList
   * @request PATCH:/EntityLists/{id}
   * @response `200` `EntityListResponseDataResponse` Success
   */
  updateEntityList = (id: number, data: EntityListUpdateRequest, params: RequestParams = {}) =>
    this.http.request<EntityListResponseDataResponse, any>({
      path: `/EntityLists/${id}`,
      method: 'PATCH',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags EntityLists
   * @name DeleteEntityList
   * @request DELETE:/EntityLists/{id}
   * @response `200` `void` Success
   */
  deleteEntityList = (id: number, params: RequestParams = {}) =>
    this.http.request<void, any>({
      path: `/EntityLists/${id}`,
      method: 'DELETE',
      ...params,
    });
  /**
   * No description
   *
   * @tags EntityLists
   * @name GetEntityListCalculations
   * @request GET:/EntityLists/{id}/Calculations
   * @response `200` `CalculationResponseCollectionResponse` Success
   */
  getEntityListCalculations = (
    id: number,
    query?: {
      /** @format int32 */
      skip?: number;
      /** @format int32 */
      top?: number;
      inlineCount?: boolean;
      search?: string;
      sortings?: Sorting[];
      conditions?: Condition[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<CalculationResponseCollectionResponse, any>({
      path: `/EntityLists/${id}/Calculations`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags EntityLists
   * @name GetLatestEntityListItems
   * @request GET:/EntityLists/{id}/Items
   * @response `200` `EntityListItemResponseCollectionResponse` Success
   */
  getLatestEntityListItems = (
    id: number,
    query?: {
      /** @format int32 */
      skip?: number;
      /** @format int32 */
      top?: number;
      inlineCount?: boolean;
      search?: string;
      sortings?: Sorting[];
      conditions?: Condition[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<EntityListItemResponseCollectionResponse, any>({
      path: `/EntityLists/${id}/Items`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags EntityLists
   * @name PutEntityListItems
   * @request PUT:/EntityLists/{id}/Items
   * @response `200` `void` Success
   */
  putEntityListItems = (
    id: number,
    data: EntityListImportVerificationRequest,
    params: RequestParams = {},
  ) =>
    this.http.request<void, any>({
      path: `/EntityLists/${id}/Items`,
      method: 'PUT',
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags EntityLists
   * @name GetEntityListImport
   * @request GET:/EntityLists/{id}/Import
   * @response `200` `EntityListImportResponseDataResponse` Success
   */
  getEntityListImport = (id: number, params: RequestParams = {}) =>
    this.http.request<EntityListImportResponseDataResponse, any>({
      path: `/EntityLists/${id}/Import`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags EntityLists
   * @name CreateEntityListImport
   * @request POST:/EntityLists/{id}/Import
   * @response `200` `EntityListImportResponseDataResponse` Success
   */
  createEntityListImport = (
    id: number,
    data: EntityListImportRequest,
    params: RequestParams = {},
  ) =>
    this.http.request<EntityListImportResponseDataResponse, any>({
      path: `/EntityLists/${id}/Import`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags EntityLists
   * @name DeleteEntityListImport
   * @request DELETE:/EntityLists/{id}/Import
   * @response `200` `void` Success
   */
  deleteEntityListImport = (
    id: number,
    data: EntityListImportVerificationRequest,
    params: RequestParams = {},
  ) =>
    this.http.request<void, any>({
      path: `/EntityLists/${id}/Import`,
      method: 'DELETE',
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags EntityLists
   * @name GetEntityListImportSummary
   * @request GET:/EntityLists/{id}/ImportSummary
   * @response `200` `EntityListImportSummaryResponseCollectionResponse` Success
   */
  getEntityListImportSummary = (id: number, params: RequestParams = {}) =>
    this.http.request<EntityListImportSummaryResponseCollectionResponse, any>({
      path: `/EntityLists/${id}/ImportSummary`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags EntityLists
   * @name GetEntityListImportItems
   * @request GET:/EntityLists/{id}/ImportItems
   * @response `200` `EntityListImportItemResponseCollectionResponse` Success
   */
  getEntityListImportItems = (
    id: number,
    query?: {
      /** @format int32 */
      skip?: number;
      /** @format int32 */
      top?: number;
      inlineCount?: boolean;
      search?: string;
      sortings?: Sorting[];
      conditions?: Condition[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<EntityListImportItemResponseCollectionResponse, any>({
      path: `/EntityLists/${id}/ImportItems`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags EntityLists
   * @name PutEntityListImportItems
   * @request PUT:/EntityLists/{id}/ImportItems
   * @response `200` `void` Success
   */
  putEntityListImportItems = (
    id: number,
    data: EntityListImportItemsRequest,
    params: RequestParams = {},
  ) =>
    this.http.request<void, any>({
      path: `/EntityLists/${id}/ImportItems`,
      method: 'PUT',
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags EntityLists
   * @name GetVersionedEntityListItems
   * @request GET:/EntityLists/{id}/Versions/{versionId}/Items
   * @response `200` `EntityListItemResponseCollectionResponse` Success
   */
  getVersionedEntityListItems = (
    id: number,
    versionId: number,
    query?: {
      /** @format int32 */
      skip?: number;
      /** @format int32 */
      top?: number;
      inlineCount?: boolean;
      search?: string;
      sortings?: Sorting[];
      conditions?: Condition[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<EntityListItemResponseCollectionResponse, any>({
      path: `/EntityLists/${id}/Versions/${versionId}/Items`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
}
