/* eslint-disable */
/* tslint:disable */
/* Do not edit - generated by swagger-typescript-api */

import {
  ApiAction,
  BrandConfigurationResponseCollectionResponse,
  BrandConfigurationUpdateRequest,
  CalculationConfigurationRequest,
  CalculationConfigurationResponseDataResponse,
  CalculationResponseCollectionResponse,
  CategoryFieldConfigurationResponseCollectionResponse,
  CategoryInsightsResponseDataResponse,
  CategorySkuCountResponseCollectionResponse,
  CategoryWarehouseOverviewResponseCollectionResponse,
  Condition,
  ConfigurationFieldType,
  ConfigurationRequest,
  ConfigurationResponseDataResponse,
  DeleteRegionFlowGroupConfigurationRequest,
  DeleteRegionFlowGroupRequest,
  FieldConfigurationDeleteRequest,
  FieldConfigurationRequest,
  ProductListConfigurationRequest,
  ProductListConfigurationResponseCollectionResponse,
  ProductListConfigurationResponseDataResponse,
  ProductListConfigurationUpdateRequest,
  RegionFlowGroupRequest,
  RegionFlowGroupResponseCollectionResponse,
  RegionFlowGroupResponseDataResponse,
  SkuClassConfigurationRequest,
  SkuClassConfigurationResponseCollectionResponse,
  SkuClassConfigurationResponseDataResponse,
  Sorting,
  TargetListResponseCollectionResponse,
  TargetListResponseDataResponse,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';
export class CurrentSetup<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;
  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }
  /**
   * No description
   *
   * @tags Setup for current template
   * @name GetCurrentSetup
   * @request GET:/CurrentSetup
   * @response `200` `ConfigurationResponseDataResponse` Success
   */
  getCurrentSetup = (params: RequestParams = {}) =>
    this.http.request<ConfigurationResponseDataResponse, any>({
      path: `/CurrentSetup`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Setup for current template
   * @name PatchCurrentSetup
   * @request PATCH:/CurrentSetup
   * @response `200` `ConfigurationResponseDataResponse` Success
   */
  patchCurrentSetup = (data: ConfigurationRequest, params: RequestParams = {}) =>
    this.http.request<ConfigurationResponseDataResponse, any>({
      path: `/CurrentSetup`,
      method: 'PATCH',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Setup for current template
   * @name LoadFromCalculationCurrentConfiguration
   * @request POST:/CurrentSetup/LoadFromCalculation/{calculationId}
   * @response `200` `void` Success
   */
  loadFromCalculationCurrentConfiguration = (calculationId: number, params: RequestParams = {}) =>
    this.http.request<void, any>({
      path: `/CurrentSetup/LoadFromCalculation/${calculationId}`,
      method: 'POST',
      ...params,
    });
  /**
   * No description
   *
   * @tags Setup for current template
   * @name GetOverlappingCalculations
   * @request GET:/CurrentSetup/OverlappingCalculations
   * @response `200` `CalculationResponseCollectionResponse` Success
   */
  getOverlappingCalculations = (params: RequestParams = {}) =>
    this.http.request<CalculationResponseCollectionResponse, any>({
      path: `/CurrentSetup/OverlappingCalculations`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Setup for current template
   * @name GetCurrentCategories
   * @request GET:/CurrentSetup/Categories
   * @response `200` `CategorySkuCountResponseCollectionResponse` Success
   */
  getCurrentCategories = (
    query?: {
      /** @format int32 */
      skip?: number;
      /** @format int32 */
      top?: number;
      inlineCount?: boolean;
      search?: string;
      sortings?: Sorting[];
      conditions?: Condition[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<CategorySkuCountResponseCollectionResponse, any>({
      path: `/CurrentSetup/Categories`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Setup for current template
   * @name GetCurrentDeviations
   * @request GET:/CurrentSetup/Categories/Deviations
   * @response `200` `CategoryFieldConfigurationResponseCollectionResponse` Success
   */
  getCurrentDeviations = (params: RequestParams = {}) =>
    this.http.request<CategoryFieldConfigurationResponseCollectionResponse, any>({
      path: `/CurrentSetup/Categories/Deviations`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Setup for current template
   * @name GetCurrentCategoryConfigurations
   * @request GET:/CurrentSetup/Categories/{categoryId}/Configurations
   * @response `200` `CategoryFieldConfigurationResponseCollectionResponse` Success
   */
  getCurrentCategoryConfigurations = (categoryId: number, params: RequestParams = {}) =>
    this.http.request<CategoryFieldConfigurationResponseCollectionResponse, any>({
      path: `/CurrentSetup/Categories/${categoryId}/Configurations`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Setup for current template
   * @name PatchCurrentCategoryConfiguration
   * @request PATCH:/CurrentSetup/Categories/{categoryId}/Configurations
   * @response `200` `CategoryFieldConfigurationResponseCollectionResponse` Success
   */
  patchCurrentCategoryConfiguration = (
    categoryId: number,
    data: FieldConfigurationRequest[],
    params: RequestParams = {},
  ) =>
    this.http.request<CategoryFieldConfigurationResponseCollectionResponse, any>({
      path: `/CurrentSetup/Categories/${categoryId}/Configurations`,
      method: 'PATCH',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Setup for current template
   * @name DeleteCurrentCategoryConfigurations
   * @request DELETE:/CurrentSetup/Categories/{categoryId}/Configurations
   * @response `200` `void` Success
   */
  deleteCurrentCategoryConfigurations = (
    categoryId: number,
    data: FieldConfigurationDeleteRequest[],
    params: RequestParams = {},
  ) =>
    this.http.request<void, any>({
      path: `/CurrentSetup/Categories/${categoryId}/Configurations`,
      method: 'DELETE',
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Setup for current template
   * @name DeleteCurrentCategoryEntityListConfigurations
   * @request DELETE:/CurrentSetup/Categories/{categoryId}/EntityLists/{entityListId}/ConfigurationFieldType/{configurationFieldType}
   * @response `200` `void` Success
   */
  deleteCurrentCategoryEntityListConfigurations = (
    categoryId: number,
    entityListId: number,
    configurationFieldType: ConfigurationFieldType,
    params: RequestParams = {},
  ) =>
    this.http.request<void, any>({
      path: `/CurrentSetup/Categories/${categoryId}/EntityLists/${entityListId}/ConfigurationFieldType/${configurationFieldType}`,
      method: 'DELETE',
      ...params,
    });
  /**
   * No description
   *
   * @tags Setup for current template
   * @name DeleteCurrentCategoryDeviationsApiAction
   * @request DELETE:/CurrentSetup/Categories/{categoryId}/Deviations/ApiAction/{apiAction}
   * @response `200` `CategoryFieldConfigurationResponseCollectionResponse` Success
   */
  deleteCurrentCategoryDeviationsApiAction = (
    categoryId: number,
    apiAction: ApiAction,
    params: RequestParams = {},
  ) =>
    this.http.request<CategoryFieldConfigurationResponseCollectionResponse, any>({
      path: `/CurrentSetup/Categories/${categoryId}/Deviations/ApiAction/${apiAction}`,
      method: 'DELETE',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Setup for current template
   * @name GetCurrentCategoryInsights
   * @request GET:/CurrentSetup/Categories/{categoryId}/Insights
   * @response `200` `CategoryInsightsResponseDataResponse` Success
   */
  getCurrentCategoryInsights = (categoryId: number, params: RequestParams = {}) =>
    this.http.request<CategoryInsightsResponseDataResponse, any>({
      path: `/CurrentSetup/Categories/${categoryId}/Insights`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Setup for current template
   * @name GetCategoryWarehouseOverview
   * @request GET:/CurrentSetup/Categories/{categoryId}/WarehouseOverviews
   * @deprecated
   * @response `200` `CategoryWarehouseOverviewResponseCollectionResponse` Success
   */
  getCategoryWarehouseOverview = (categoryId: number, params: RequestParams = {}) =>
    this.http.request<CategoryWarehouseOverviewResponseCollectionResponse, any>({
      path: `/CurrentSetup/Categories/${categoryId}/WarehouseOverviews`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Setup for current template
   * @name GetCurrentSkuClassConfigurations
   * @request GET:/CurrentSetup/SkuClasses/Configurations
   * @response `200` `SkuClassConfigurationResponseCollectionResponse` Success
   */
  getCurrentSkuClassConfigurations = (params: RequestParams = {}) =>
    this.http.request<SkuClassConfigurationResponseCollectionResponse, any>({
      path: `/CurrentSetup/SkuClasses/Configurations`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Setup for current template
   * @name PatchCurrentSkuClassConfigurations
   * @request PATCH:/CurrentSetup/SkuClasses/{skuClassId}/Configurations
   * @response `200` `SkuClassConfigurationResponseDataResponse` Success
   */
  patchCurrentSkuClassConfigurations = (
    skuClassId: number,
    data: SkuClassConfigurationRequest,
    params: RequestParams = {},
  ) =>
    this.http.request<SkuClassConfigurationResponseDataResponse, any>({
      path: `/CurrentSetup/SkuClasses/${skuClassId}/Configurations`,
      method: 'PATCH',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Setup for current template
   * @name CalculationConfigurationsList
   * @request GET:/CurrentSetup/CalculationConfigurations
   * @response `200` `CalculationConfigurationResponseDataResponse` Success
   */
  calculationConfigurationsList = (params: RequestParams = {}) =>
    this.http.request<CalculationConfigurationResponseDataResponse, any>({
      path: `/CurrentSetup/CalculationConfigurations`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Setup for current template
   * @name CalculationConfigurationsPartialUpdate
   * @request PATCH:/CurrentSetup/CalculationConfigurations
   * @response `200` `CalculationConfigurationResponseDataResponse` Success
   */
  calculationConfigurationsPartialUpdate = (
    data: CalculationConfigurationRequest,
    params: RequestParams = {},
  ) =>
    this.http.request<CalculationConfigurationResponseDataResponse, any>({
      path: `/CurrentSetup/CalculationConfigurations`,
      method: 'PATCH',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Setup for current template
   * @name GetCurrentProductListConfigurationsV2
   * @request GET:/CurrentSetup/ProductLists/Configurations/V2
   * @response `200` `ProductListConfigurationResponseCollectionResponse` Success
   */
  getCurrentProductListConfigurationsV2 = (
    query?: {
      /** @format int32 */
      skip?: number;
      /** @format int32 */
      top?: number;
      inlineCount?: boolean;
      search?: string;
      sortings?: Sorting[];
      conditions?: Condition[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<ProductListConfigurationResponseCollectionResponse, any>({
      path: `/CurrentSetup/ProductLists/Configurations/V2`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Setup for current template
   * @name DeleteCurrentProductListConfigurations
   * @request DELETE:/CurrentSetup/ProductLists/Configurations
   * @response `200` `void` Success
   */
  deleteCurrentProductListConfigurations = (params: RequestParams = {}) =>
    this.http.request<void, any>({
      path: `/CurrentSetup/ProductLists/Configurations`,
      method: 'DELETE',
      ...params,
    });
  /**
   * No description
   *
   * @tags Setup for current template
   * @name PostCurrentProductListConfiguration
   * @request POST:/CurrentSetup/ProductLists/{id}/Configuration
   * @response `200` `ProductListConfigurationResponseDataResponse` Success
   */
  postCurrentProductListConfiguration = (
    id: number,
    data: ProductListConfigurationRequest,
    params: RequestParams = {},
  ) =>
    this.http.request<ProductListConfigurationResponseDataResponse, any>({
      path: `/CurrentSetup/ProductLists/${id}/Configuration`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Setup for current template
   * @name PatchCurrentProductListConfiguration
   * @request PATCH:/CurrentSetup/ProductLists/{id}/Configuration
   * @response `200` `ProductListConfigurationResponseDataResponse` Success
   */
  patchCurrentProductListConfiguration = (
    id: number,
    data: ProductListConfigurationUpdateRequest,
    params: RequestParams = {},
  ) =>
    this.http.request<ProductListConfigurationResponseDataResponse, any>({
      path: `/CurrentSetup/ProductLists/${id}/Configuration`,
      method: 'PATCH',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Setup for current template
   * @name DeleteCurrentProductListConfiguration
   * @request DELETE:/CurrentSetup/ProductLists/{id}/Configuration
   * @response `200` `void` Success
   */
  deleteCurrentProductListConfiguration = (id: number, params: RequestParams = {}) =>
    this.http.request<void, any>({
      path: `/CurrentSetup/ProductLists/${id}/Configuration`,
      method: 'DELETE',
      ...params,
    });
  /**
   * No description
   *
   * @tags Setup for current template
   * @name GetCurrentBrands
   * @request GET:/CurrentSetup/Brands
   * @response `200` `BrandConfigurationResponseCollectionResponse` Success
   */
  getCurrentBrands = (
    query?: {
      /** @format int32 */
      skip?: number;
      /** @format int32 */
      top?: number;
      inlineCount?: boolean;
      search?: string;
      sortings?: Sorting[];
      conditions?: Condition[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<BrandConfigurationResponseCollectionResponse, any>({
      path: `/CurrentSetup/Brands`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Setup for current template
   * @name PostCurrentBrandConfigurations
   * @request POST:/CurrentSetup/Brands/Configurations
   * @response `200` `void` Success
   */
  postCurrentBrandConfigurations = (
    data: BrandConfigurationUpdateRequest[],
    params: RequestParams = {},
  ) =>
    this.http.request<void, any>({
      path: `/CurrentSetup/Brands/Configurations`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Setup for current template
   * @name DeleteCurrentBrandConfigurations
   * @request DELETE:/CurrentSetup/Brands/Configurations
   * @response `200` `void` Success
   */
  deleteCurrentBrandConfigurations = (params: RequestParams = {}) =>
    this.http.request<void, any>({
      path: `/CurrentSetup/Brands/Configurations`,
      method: 'DELETE',
      ...params,
    });
  /**
   * No description
   *
   * @tags Setup for current template
   * @name PostCurrentBrandConfiguration
   * @request POST:/CurrentSetup/Brands/{id}/Configuration
   * @response `200` `void` Success
   */
  postCurrentBrandConfiguration = (id: number, params: RequestParams = {}) =>
    this.http.request<void, any>({
      path: `/CurrentSetup/Brands/${id}/Configuration`,
      method: 'POST',
      ...params,
    });
  /**
   * No description
   *
   * @tags Setup for current template
   * @name DeleteCurrentBrandConfiguration
   * @request DELETE:/CurrentSetup/Brands/{id}/Configuration
   * @response `200` `void` Success
   */
  deleteCurrentBrandConfiguration = (id: number, params: RequestParams = {}) =>
    this.http.request<void, any>({
      path: `/CurrentSetup/Brands/${id}/Configuration`,
      method: 'DELETE',
      ...params,
    });
  /**
   * No description
   *
   * @tags Setup for current template
   * @name GetCurrentRegionFlowGroups
   * @request GET:/CurrentSetup/RegionFlowGroups
   * @response `200` `RegionFlowGroupResponseCollectionResponse` Success
   */
  getCurrentRegionFlowGroups = (params: RequestParams = {}) =>
    this.http.request<RegionFlowGroupResponseCollectionResponse, any>({
      path: `/CurrentSetup/RegionFlowGroups`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Setup for current template
   * @name PostCurrentRegionFlowGroup
   * @request POST:/CurrentSetup/RegionFlowGroups
   * @response `200` `void` Success
   */
  postCurrentRegionFlowGroup = (params: RequestParams = {}) =>
    this.http.request<void, any>({
      path: `/CurrentSetup/RegionFlowGroups`,
      method: 'POST',
      ...params,
    });
  /**
   * No description
   *
   * @tags Setup for current template
   * @name DeleteRegionFlowGroup
   * @request DELETE:/CurrentSetup/RegionFlowGroups
   * @response `200` `void` Success
   */
  deleteRegionFlowGroup = (data: DeleteRegionFlowGroupRequest, params: RequestParams = {}) =>
    this.http.request<void, any>({
      path: `/CurrentSetup/RegionFlowGroups`,
      method: 'DELETE',
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Setup for current template
   * @name GetRegionFlowGroup
   * @request GET:/CurrentSetup/RegionFlowGroups/{id}
   * @response `200` `RegionFlowGroupResponseDataResponse` Success
   */
  getRegionFlowGroup = (id: number, params: RequestParams = {}) =>
    this.http.request<RegionFlowGroupResponseDataResponse, any>({
      path: `/CurrentSetup/RegionFlowGroups/${id}`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Setup for current template
   * @name PostRegionFlowGroupConfiguration
   * @request POST:/CurrentSetup/RegionFlowGroups/{id}/Configuration
   * @response `200` `RegionFlowGroupResponseCollectionResponse` Success
   */
  postRegionFlowGroupConfiguration = (
    id: number,
    data: RegionFlowGroupRequest,
    params: RequestParams = {},
  ) =>
    this.http.request<RegionFlowGroupResponseCollectionResponse, any>({
      path: `/CurrentSetup/RegionFlowGroups/${id}/Configuration`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Setup for current template
   * @name DeleteRegionFlowConfiguration
   * @request DELETE:/CurrentSetup/RegionFlowGroups/{id}/Configuration
   * @response `200` `void` Success
   */
  deleteRegionFlowConfiguration = (
    id: number,
    data: DeleteRegionFlowGroupConfigurationRequest,
    params: RequestParams = {},
  ) =>
    this.http.request<void, any>({
      path: `/CurrentSetup/RegionFlowGroups/${id}/Configuration`,
      method: 'DELETE',
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Setup for current template
   * @name GetCurrentTargetListConfigurations
   * @request GET:/CurrentSetup/TargetLists/Configurations
   * @deprecated
   * @response `200` `TargetListResponseCollectionResponse` Success
   */
  getCurrentTargetListConfigurations = (params: RequestParams = {}) =>
    this.http.request<TargetListResponseCollectionResponse, any>({
      path: `/CurrentSetup/TargetLists/Configurations`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Setup for current template
   * @name PostCurrentTargetListConfigurations
   * @request POST:/CurrentSetup/TargetLists/{id}/Configuration
   * @deprecated
   * @response `200` `TargetListResponseDataResponse` Success
   */
  postCurrentTargetListConfigurations = (id: number, params: RequestParams = {}) =>
    this.http.request<TargetListResponseDataResponse, any>({
      path: `/CurrentSetup/TargetLists/${id}/Configuration`,
      method: 'POST',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Setup for current template
   * @name DeleteCurrentTargetListConfigurations
   * @request DELETE:/CurrentSetup/TargetLists/{id}/Configuration
   * @deprecated
   * @response `200` `void` Success
   */
  deleteCurrentTargetListConfigurations = (id: number, params: RequestParams = {}) =>
    this.http.request<void, any>({
      path: `/CurrentSetup/TargetLists/${id}/Configuration`,
      method: 'DELETE',
      ...params,
    });
}
