import { TextSizeType } from './typographyTypes';
import { COLORS, STYLE } from '../../styles/style';
import { CSSProperties } from 'react';
import { styled, css } from 'styled-components';

export type TextType = 'default' | 'danger' | 'success' | 'light' | 'warning';

export interface TextProps {
  $size?: TextSizeType;
  $bold?: boolean;
  $semiBold?: boolean;
  $width?: CSSProperties['width'];
  $maxWidth?: CSSProperties['maxWidth'];
  $type?: TextType;
  $center?: boolean;
  $pointer?: boolean;
}

type MarginType = 'none' | 'small' | 'normal' | 'large';

interface TitleProps extends TextProps {
  $marginTop?: MarginType;
  $marginBottom?: MarginType;
}

const marginSizes: Record<MarginType, CSSProperties['marginTop']> = {
  large: '2rem',
  normal: '1rem',
  small: '0.5rem',
  none: 0,
};

export const Text = styled.span<TextProps>`
  ${({ $maxWidth, $width }) => {
    if (!($maxWidth || $width)) {
      return;
    }

    return css`
      display: inline-block;
      ${$maxWidth ? `max-width: ${$maxWidth};` : ''}
      ${$width ? `width: ${$width};` : ''}
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `;
  }}

  ${({ $center }) => $center && `text-align: center;`}
  ${({ $pointer }) => $pointer && `cursor: pointer;`}

  ${({ $type }) => {
    switch ($type) {
      case 'danger':
        return css`
          color: ${COLORS.DANGER};
        `;
      case 'warning':
        return css`
          color: ${COLORS.WEB_ORANGE};
        `;
      case 'success':
        return css`
          color: ${COLORS.SUCCESS};
        `;
      case 'light':
        return css`
          color: ${COLORS.GREY};
        `;
      default:
        return;
    }
  }}


  font-size: ${({ $size = 'normal' }) => STYLE.TEXT_FONT_SIZE[$size]};
  ${({ $bold, $semiBold }) => {
    if ($bold) {
      return 'font-weight: 700;';
    }
    if ($semiBold) {
      return 'font-weight: 500;';
    }
  }}
`;

export const Title = styled.div<TitleProps>`
  font-size: ${({ $size = 'normal' }) => STYLE.TITLE_FONT_SIZE[$size]};
  font-weight: 500;
  line-height: 1.3;
  white-space: normal;

  ${({ $marginTop }) =>
    $marginTop &&
    css`
      margin-top: ${marginSizes[$marginTop]};
    `}
  ${({ $marginBottom }) =>
    $marginBottom &&
    css`
      margin-bottom: ${marginSizes[$marginBottom]};
    `}
`;
