import { styled, css } from 'styled-components';
import { Input as AntdInput, InputProps as AntdInputProps } from 'antd';
import { Text, TextType } from '../typography/Typography/Typography';
import React from 'react';
import { Flex, FlexDirections } from '../layout/Flex';

const inputStyles = css`
  border-radius: 0.5rem;
  border: 1px solid #d9d9d9;
  font-size: 1.2rem;

  &:focus {
    outline: none;
  }
`;

export const StyledInput = styled(AntdInput)`
  ${inputStyles}
`;
export const StyledInputPassword = styled(AntdInput.Password)`
  ${inputStyles}
`;

export interface InputProps extends AntdInputProps {
  infoText?: string;
  infoTextType?: TextType;
  infoTextDirection?: FlexDirections;
}

/**
 * Ant Design Input with additional text of different type to be displayed after
 * or below the input field.
 * @param infoText Text to be displayed
 * @param infoTextType Type of the text to indicate error or success
 * @param infoTextDirection Direction of the text (row or column to specify horizontal or vertical direction)
 * @param rest
 * @constructor
 */
export const Input: React.FC<InputProps> = ({
  infoText,
  infoTextType,
  infoTextDirection = FlexDirections.ROW,
  ...rest
}) => {
  return (
    <Flex $flexWrap="nowrap" $alignItems="center" $gap="0.5rem" $flexDirection={infoTextDirection}>
      {rest.type === 'password' ? <StyledInputPassword {...rest} /> : <StyledInput {...rest} />}
      {infoText && <Text $type={infoTextType}>{infoText}</Text>}
    </Flex>
  );
};
