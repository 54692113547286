import { styled } from 'styled-components';
import { Layout } from 'antd';
import { CSSProperties, PropsWithChildren } from 'react';
import { STYLE } from '@ydistri/ds';

const { Content } = Layout;

export const LayoutFull = styled(Layout)`
  flex-direction: column;
  height: 100vh;
  min-height: 100vh;
  max-height: 100vh;
`;

export const LayoutHorizontal = styled(Layout)`
  flex-direction: row;
`;

const LayoutVertical = styled(Layout)`
  flex-direction: column;
`;

export const LayoutVerticalMain = styled(LayoutVertical)`
  position: relative;
  ${p => p.theme.globalPadding && `padding: ${p.theme.globalPadding} ${p.theme.globalPadding} 0;`}
`;

interface MainContentWrapperStyledProps {
  $flexDirection?: CSSProperties['flexDirection'];
}

const MainContentWrapperStyled = styled(Content)<MainContentWrapperStyledProps>`
  position: relative;
  display: flex;
  flex-grow: 1;
  flex-direction: ${p => p.$flexDirection ?? 'row'};
  container-type: inline-size;
  container-name: maincontent;

  //TODO: rework to container query
  //@container maincontent (max-width: 768px) {
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

interface MainContentWrapperProps extends MainContentWrapperStyledProps, PropsWithChildren {}

export const MainContentWrapper: React.FC<MainContentWrapperProps> = ({
  children,
  $flexDirection,
  ...rest
}) => {
  return (
    <MainContentWrapperStyled
      data-type="MainContentWrapper"
      $flexDirection={$flexDirection}
      {...rest}
    >
      {children}
    </MainContentWrapperStyled>
  );
};

export const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 20%;

  overflow-y: auto;

  ${STYLE.SCROLLBAR}
`;
