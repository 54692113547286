import { Button as AntdButton, ButtonProps as AntdButtonProps } from 'antd';
import React, { PropsWithChildren } from 'react';
import { COLORS, computeRemSize } from '@ydistri/ds';
import { styled, css, DefaultTheme, CSSProp } from 'styled-components';

type ButtonColorVariant =
  | 'primary'
  | 'selected'
  | 'ghost'
  | 'ghostPrimary'
  | 'ghostDanger'
  | 'danger';
type ButtonTextVariant = 'regular' | 'semibold';
type ButtonSizeVariant = 'xSmall' | 'small' | 'normal' | 'large';
type BorderVariant = 'none' | 'transparent' | 'full';

export const buttonVariantColorStyles = (
  theme: DefaultTheme,
  variant: ButtonColorVariant = 'primary',
): CSSProp[] =>
  ({
    primary: css`
      color: ${theme.colors.dark};
      background: ${theme.colors.primary};
      border: ${computeRemSize(1)} solid ${theme.colors.primary};

      &:hover {
        color: white !important;
      }

      &:disabled {
        background: ${COLORS.PRIMARY_STEPS[2]};
        color: ${COLORS.GREY};
        cursor: not-allowed;

        &:hover {
          color: ${COLORS.GREY} !important;
        }
      }
    `,
    selected: css`
      color: white;
      background: ${theme.colors.dark};
      border: ${computeRemSize(1)} solid ${theme.colors.dark};

      &:hover:not(:disabled)) {
        color: white !important;
        background: ${theme.colors.dark} !important;
        border: ${computeRemSize(1)} solid ${theme.colors.dark} !important;
      }
    `,
    ghost: css`
      color: ${theme.colors.dark};
      background: transparent;
      border: ${computeRemSize(1)} solid ${theme.colors.border};

      &:hover:not(:disabled)) {
        color: white !important;
        background: ${theme.colors.dark} !important;
        border: ${computeRemSize(1)} solid ${theme.colors.border} !important;
      }
    `,
    ghostPrimary: css`
      color: ${theme.colors.primary};
      background: transparent;
      border: ${computeRemSize(1)} solid ${theme.colors.primary};

      &:hover:not(:disabled)) {
        color: ${theme.colors.light} !important;
        background: ${theme.colors.primary} !important;
        border: ${computeRemSize(1)} solid ${theme.colors.primary} !important;
      }
    `,
    ghostDanger: css`
      color: ${theme.colors.danger};
      background: transparent;
      border: ${computeRemSize(1)} solid ${theme.colors.danger};

      &:hover:not(:disabled)) {
        color: ${theme.colors.light} !important;
        background: ${theme.colors.danger} !important;
        border: ${computeRemSize(1)} solid ${theme.colors.danger} !important;
      }
    `,
    danger: css`
      color: white;
      background: ${theme.colors.danger};
      border: ${computeRemSize(1)} solid ${theme.colors.danger};

      &:hover:not(:disabled)) {
        color: white !important;
        background: ${theme.colors.danger} !important;
        border: ${computeRemSize(1)} solid ${theme.colors.danger} !important;
        opacity: 0.8;
      }
    `,
  })[variant];

export const buttonVariantTextStyles = (
  theme: DefaultTheme,
  variant: ButtonTextVariant = 'regular',
): CSSProp[] =>
  ({
    regular: css`
      font-weight: 400;
    `,
    semibold: css`
      font-weight: 500;
    `,
  })[variant];

export const buttonVariantSizeStyles = (
  theme: DefaultTheme,
  variant: ButtonSizeVariant = 'normal',
): CSSProp[] =>
  ({
    xSmall: css`
      line-height: ${computeRemSize(24)};
      height: ${computeRemSize(24)};
      padding: ${computeRemSize(4)} !important;
    `,
    small: css`
      line-height: ${computeRemSize(24)};
      height: ${computeRemSize(24)};
    `,
    normal: css`
      line-height: ${computeRemSize(32)};
      height: ${computeRemSize(32)};
    `,
    large: css`
      line-height: ${computeRemSize(40)};
      height: ${computeRemSize(40)};
    `,
  })[variant];

export const borderVariantStyles = (
  theme: DefaultTheme,
  variant: BorderVariant = 'none',
): CSSProp[] =>
  ({
    none: css``,
    transparent: css`
      border: ${computeRemSize(1)} solid transparent !important;
    `,
    full: css`
      border: ${computeRemSize(1)} solid black !important;
    `,
  })[variant];

interface StyledButtonProps {
  //.attrs({ size: 'large' })
  $colorVariant?: ButtonColorVariant;
  $textVariant?: ButtonTextVariant;
  $sizeVariant?: ButtonSizeVariant;
  $borderVariant?: BorderVariant;
}

export const baseButtonStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;

  gap: ${computeRemSize(8)};
  padding: ${computeRemSize(8)} ${computeRemSize(16)} !important;
  border-radius: ${computeRemSize(3)} !important;
  font-size: ${computeRemSize(14)};
  transition: all 0.3s;
  cursor: pointer;

  span {
    display: flex;
    padding-top: ${computeRemSize(
      2,
    )}; //for some reason, its impossible to correctly center the text vertically. maybe because of font
  }

  &:active {
    transform: translateY(${computeRemSize(2)});
  }
`;

const StyledButton = styled(AntdButton)<StyledButtonProps>`
  ${baseButtonStyles}

  ${({ theme, $colorVariant }) => buttonVariantColorStyles(theme, $colorVariant)}
  ${({ theme, $textVariant }) => buttonVariantTextStyles(theme, $textVariant)}
  ${({ theme, $sizeVariant }) => buttonVariantSizeStyles(theme, $sizeVariant)}
  ${({ theme, $borderVariant }) => borderVariantStyles(theme, $borderVariant)}
`;

interface ButtonProps extends PropsWithChildren, AntdButtonProps, StyledButtonProps {
  suffix?: React.ReactNode;
}

export const Button: React.FC<ButtonProps> = ({ suffix, children, icon, ...props }) => {
  return (
    <StyledButton {...props}>
      {icon}
      {children}
      {suffix}
    </StyledButton>
  );
};
