import React, {
  CSSProperties,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { styled } from 'styled-components';
import { fixedSectionStyles, SectionBase } from '../SectionBase/SectionBase';
import { COLORS, computeRemSize, STYLE } from '../styles/style';
import { Title } from '../typography/Typography/Typography';
import { IoChevronDown } from 'react-icons/io5';
import { OpenIcon } from '../../../../../apps/ydistri/src/app/components/global/CategoryTree/categoryTreeStyledComponents';

export const SectionHeaderWrapper = styled.div`
  display: inline-flex;
  gap: 1rem;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  white-space: nowrap;
  padding-right: 2rem;
`;

export const SectionHeaderIndicator = styled.div`
  margin-right: ${computeRemSize(15)};
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: ${COLORS.PRIMARY};
`;

interface SectionHeaderProps extends PropsWithChildren {
  collapsible?: boolean;
  open?: boolean;
  showBulletPoint?: boolean;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SectionHeader: React.FC<SectionHeaderProps> = ({
  children,
  collapsible = false,
  open = true,
  showBulletPoint = true,
  setOpen,
}) => {
  const toggleOpenHandler = useCallback(() => setOpen?.(p => !p), [setOpen]);

  return (
    <SectionHeaderWrapper data-type="section-header">
      {!collapsible && showBulletPoint && <SectionHeaderIndicator />}
      {collapsible && (
        <OpenIcon $isOpened={open} $canBeOpened={true} onClick={toggleOpenHandler}>
          <IoChevronDown />
        </OpenIcon>
      )}
      <Title $size="large" $semiBold>
        {children}
      </Title>
    </SectionHeaderWrapper>
  );
};

export interface SectionProps {
  id?: string;
  children?: ReactNode;

  collapsible?: boolean;
  open?: boolean;
  showBulletPoint?: boolean;

  header?: string | ReactNode;
  headerActions?: ReactNode;
  headerActionsJustifyContent?: CSSProperties['justifyContent'];
  headerActionsIncludeMarginBottom?: boolean;
  contentActions?: ReactNode;

  $fixed?: boolean;
  $ratio?: number;
  $shrink?: number;
  $basis?: number;
  $scrollableContent?: boolean;
  $contentMarginTop?: boolean;
  $width?: string;
  $minWidth?: string;
  $height?: string;
  $minHeight?: string;
  $backgroundColor?: string;
}

type SectionContentProps = Pick<SectionProps, '$scrollableContent' | '$contentMarginTop'> & {
  $collapsible?: boolean;
  $open?: boolean;
};

const StyledSection = styled(SectionBase)<SectionProps>`
  flex-direction: column;
  flex-grow: ${({ $ratio }) => $ratio ?? 1};
  ${({ $shrink }) => typeof $shrink === 'number' && `flex-shrink: ${$shrink};`};
  ${({ $basis }) => $basis && `flex-basis: calc(${$basis}% - 1rem);`};
  padding-bottom: 0.5rem;
  padding-top: ${computeRemSize(16)};
  ${({ $scrollableContent }) => {
    if (typeof $scrollableContent !== 'undefined') {
      if ($scrollableContent) {
        return ' min-height: 0';
      }
    }
  }};
  ${({ $width }) => $width && `width: ${$width};`};
  ${({ $minWidth }) => $minWidth && `min-width: ${$minWidth};`};
  ${({ $height }) => $height && `height: ${$height};`};
  ${({ $minHeight }) => $minHeight && `min-height: ${$minHeight};`};
  ${({ $backgroundColor }) => $backgroundColor && `background-color: ${$backgroundColor};`};
  ${({ $fixed }) => $fixed && fixedSectionStyles}
`;

export const SectionContent = styled.div<SectionContentProps>`
  padding: 0 1rem 0 1rem;
  margin-top: ${p => (p.$contentMarginTop ? '1rem' : 0)};
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex-grow: 1;

  ${({ $collapsible, $open }) => $collapsible && !$open && `display: none;`};
  ${({ $scrollableContent }) => $scrollableContent && `overflow-y: auto;${STYLE.SCROLLBAR}`}
`;

export const SectionDivider = styled.div<{ $modal?: boolean }>`
  border-bottom: 1px solid ${COLORS.BORDER};
  height: 1px;
  margin: ${p =>
    p.$modal ? `0 -${computeRemSize(24)} 0 -${computeRemSize(24)}` : `0 -1rem 0 -1rem`};
`;

/**
 * Common behaviour for header and content actions
 */
const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-right: 1rem;
  gap: 1rem;
`;

/**
 * Display header on the left and actions on the right
 */
export const SectionHeaderWithActionsWrapper = styled(ActionWrapper)<{
  $headerActionJustifyContent?: CSSProperties['justifyContent'];
  $includeMarginBottom?: boolean;
}>`
  justify-content: ${props => props.$headerActionJustifyContent ?? 'space-between'};
  align-items: flex-start;
  margin-bottom: ${props => (props.$includeMarginBottom ? '.5rem' : '0')};
  margin-left: ${computeRemSize(16)};
`;

/**
 * Display content actions on the right
 */
const ContentActionsWrapper = styled(ActionWrapper)`
  justify-content: flex-start;
  padding-left: 1rem;
`;

export const Section = ({
  children,
  header,
  collapsible = false,
  open = true,
  showBulletPoint = true,
  $ratio,
  $basis,
  $shrink,
  $scrollableContent,
  headerActions,
  headerActionsJustifyContent,
  headerActionsIncludeMarginBottom = true,
  contentActions,
  $contentMarginTop = true,
  ...rest
}: SectionProps): React.JSX.Element => {
  //internal open state
  const [openState, setOpenState] = React.useState(collapsible ? open : true);

  //if $open prop changes, update internal state
  useEffect(() => {
    setOpenState(open);
  }, [open]);

  const renderedHeader = useMemo(() => {
    if (!header && !headerActions) return null;

    let headerElement;
    if (header) {
      if (typeof header === 'string') {
        headerElement = (
          <SectionHeader
            data-type="section-header"
            open={openState}
            collapsible={collapsible}
            setOpen={setOpenState}
            showBulletPoint={showBulletPoint}
          >
            {header}
          </SectionHeader>
        );
      } else {
        headerElement = header;
      }
    }

    return (
      <SectionHeaderWithActionsWrapper
        data-type="SectionHeaderWithActions"
        $headerActionJustifyContent={headerActionsJustifyContent}
        $includeMarginBottom={headerActionsIncludeMarginBottom}
      >
        {headerElement}
        {headerActions ?? null}
      </SectionHeaderWithActionsWrapper>
    );
  }, [
    collapsible,
    header,
    headerActions,
    headerActionsIncludeMarginBottom,
    headerActionsJustifyContent,
    openState,
    showBulletPoint,
  ]);

  return (
    <StyledSection
      $ratio={$ratio}
      $basis={$basis}
      $shrink={$shrink}
      data-type="section"
      $scrollableContent={$scrollableContent}
      {...rest}
    >
      {renderedHeader}
      {contentActions && <ContentActionsWrapper>{contentActions}</ContentActionsWrapper>}
      {children && (
        <SectionContent
          $contentMarginTop={$contentMarginTop}
          $scrollableContent={$scrollableContent}
          $collapsible={collapsible}
          $open={openState}
          data-type="SectionContent"
        >
          {children}
        </SectionContent>
      )}
    </StyledSection>
  );
};

export const HalfScreenSection = styled(Section)`
  width: 49%;
  max-width: 49%;
`;
