export enum ApplicationDataType {
  BRANDS = 'brands',
  DEPARTMENTS = 'departments',
  FORCE_QUANTITIES = 'forceQuantities',
  MINIMAL_LAYERS = 'minimalLayers',
  OPEN_PURCHASE_ORDERS = 'openPurchaseOrders',
  PRODUCT_CODE = 'productCode',
  SKU_CLASSES = 'skuClasses',
  STORE_CODE = 'warehouseCode',
}
