/* eslint-disable */
/* tslint:disable */
/* Do not edit - generated by swagger-typescript-api */

import {
  CalculationResponseCollectionResponse,
  Condition,
  Sorting,
  TargetListCreateRequest,
  TargetListItemComparisonInformationResponseDataResponse,
  TargetListItemResponseCollectionResponse,
  TargetListItemsIdentificationResponseDataResponse,
  TargetListItemsRequest,
  TargetListResponseCollectionResponse,
  TargetListResponseDataResponse,
  TargetListUpdateRequest,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';
export class TargetLists<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;
  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }
  /**
   * No description
   *
   * @tags TargetLists
   * @name TargetListsList
   * @request GET:/TargetLists
   * @response `200` `TargetListResponseCollectionResponse` Success
   */
  targetListsList = (
    query?: {
      /** @format int32 */
      skip?: number;
      /** @format int32 */
      top?: number;
      inlineCount?: boolean;
      search?: string;
      sortings?: Sorting[];
      conditions?: Condition[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<TargetListResponseCollectionResponse, any>({
      path: `/TargetLists`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags TargetLists
   * @name CreateTargetList
   * @request POST:/TargetLists
   * @response `200` `TargetListResponseDataResponse` Success
   */
  createTargetList = (data: TargetListCreateRequest, params: RequestParams = {}) =>
    this.http.request<TargetListResponseDataResponse, any>({
      path: `/TargetLists`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags TargetLists
   * @name UpdateTargetList
   * @request PATCH:/TargetLists/{id}
   * @response `200` `TargetListResponseDataResponse` Success
   */
  updateTargetList = (id: number, data: TargetListUpdateRequest, params: RequestParams = {}) =>
    this.http.request<TargetListResponseDataResponse, any>({
      path: `/TargetLists/${id}`,
      method: 'PATCH',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags TargetLists
   * @name DeleteTargetList
   * @request DELETE:/TargetLists/{id}
   * @response `200` `void` Success
   */
  deleteTargetList = (id: number, params: RequestParams = {}) =>
    this.http.request<void, any>({
      path: `/TargetLists/${id}`,
      method: 'DELETE',
      ...params,
    });
  /**
   * No description
   *
   * @tags TargetLists
   * @name GetTargetListCalculations
   * @request GET:/TargetLists/{id}/Calculations
   * @response `200` `CalculationResponseCollectionResponse` Success
   */
  getTargetListCalculations = (
    id: number,
    query?: {
      /** @format int32 */
      skip?: number;
      /** @format int32 */
      top?: number;
      inlineCount?: boolean;
      search?: string;
      sortings?: Sorting[];
      conditions?: Condition[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<CalculationResponseCollectionResponse, any>({
      path: `/TargetLists/${id}/Calculations`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags TargetLists
   * @name GetSkus
   * @request GET:/TargetLists/{id}/Skus
   * @response `200` `TargetListItemResponseCollectionResponse` Success
   */
  getSkus = (
    id: number,
    query?: {
      /** @format int32 */
      skip?: number;
      /** @format int32 */
      top?: number;
      inlineCount?: boolean;
      search?: string;
      sortings?: Sorting[];
      conditions?: Condition[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<TargetListItemResponseCollectionResponse, any>({
      path: `/TargetLists/${id}/Skus`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags TargetLists
   * @name PutTargetListItems
   * @request PUT:/TargetLists/{id}/Skus
   * @response `200` `TargetListItemsIdentificationResponseDataResponse` Success
   */
  putTargetListItems = (id: number, data: TargetListItemsRequest, params: RequestParams = {}) =>
    this.http.request<TargetListItemsIdentificationResponseDataResponse, any>({
      path: `/TargetLists/${id}/Skus`,
      method: 'PUT',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags TargetLists
   * @name PatchTargetListItems
   * @request PATCH:/TargetLists/{id}/Skus
   * @response `200` `TargetListItemsIdentificationResponseDataResponse` Success
   */
  patchTargetListItems = (id: number, data: TargetListItemsRequest, params: RequestParams = {}) =>
    this.http.request<TargetListItemsIdentificationResponseDataResponse, any>({
      path: `/TargetLists/${id}/Skus`,
      method: 'PATCH',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags TargetLists
   * @name SkusCompareCreate
   * @request POST:/TargetLists/{id}/Skus/Compare
   * @response `200` `TargetListItemComparisonInformationResponseDataResponse` Success
   */
  skusCompareCreate = (id: number, data: TargetListItemsRequest, params: RequestParams = {}) =>
    this.http.request<TargetListItemComparisonInformationResponseDataResponse, any>({
      path: `/TargetLists/${id}/Skus/Compare`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
