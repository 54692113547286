/* eslint-disable */
/* tslint:disable */
/* Do not edit - generated by swagger-typescript-api */

import {
  ForgetPasswordRequest,
  ProjectResponseCollectionResponse,
  UserChangePasswordRequest,
  UserLoginRequest,
  UserResetPasswordRequest,
  UserResponseDataResponse,
  UserTokenValidationResponseDataResponse,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';
export class Me<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;
  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }
  /**
   * No description
   *
   * @tags Me
   * @name GetMe
   * @request GET:/Me
   * @response `200` `UserResponseDataResponse` Success
   */
  getMe = (params: RequestParams = {}) =>
    this.http.request<UserResponseDataResponse, any>({
      path: `/Me`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Me
   * @name ProjectsList
   * @request GET:/Me/Projects
   * @response `200` `ProjectResponseCollectionResponse` Success
   */
  projectsList = (params: RequestParams = {}) =>
    this.http.request<ProjectResponseCollectionResponse, any>({
      path: `/Me/Projects`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Me
   * @name ConfirmAccountCreate
   * @request POST:/Me/ConfirmAccount
   * @response `200` `void` Success
   */
  confirmAccountCreate = (data: UserResetPasswordRequest, params: RequestParams = {}) =>
    this.http.request<void, any>({
      path: `/Me/ConfirmAccount`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Me
   * @name SendLinkForgetPasswordCreate
   * @request POST:/Me/SendLinkForgetPassword
   * @response `200` `void` Success
   */
  sendLinkForgetPasswordCreate = (data: ForgetPasswordRequest, params: RequestParams = {}) =>
    this.http.request<void, any>({
      path: `/Me/SendLinkForgetPassword`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Me
   * @name ResetPasswordCreate
   * @request POST:/Me/ResetPassword
   * @response `200` `void` Success
   */
  resetPasswordCreate = (data: UserResetPasswordRequest, params: RequestParams = {}) =>
    this.http.request<void, any>({
      path: `/Me/ResetPassword`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Me
   * @name ValidateTokenCreate
   * @request POST:/Me/ValidateToken
   * @response `200` `UserTokenValidationResponseDataResponse` Success
   */
  validateTokenCreate = (data: UserLoginRequest, params: RequestParams = {}) =>
    this.http.request<UserTokenValidationResponseDataResponse, any>({
      path: `/Me/ValidateToken`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Me
   * @name ChangePassword
   * @request POST:/Me/ChangePassword
   * @response `200` `void` Success
   */
  changePassword = (data: UserChangePasswordRequest, params: RequestParams = {}) =>
    this.http.request<void, any>({
      path: `/Me/ChangePassword`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      ...params,
    });
}
