import { styled } from 'styled-components';
import React from 'react';
import { formatNumber } from '@ydistri/utils';
import { COLORS } from '@ydistri/ds';

const HeaderRow = styled.tr<{ border?: boolean }>`
  td {
    border-bottom: ${p => (p.border ? `2px solid ${COLORS.BORDER}` : 'none')};
    font-size: 1.2rem;
  }
`;

const NumberCell = styled.td`
  text-align: right;
  padding-left: 1rem;
`;

const TitleCell = styled.td`
  font-style: italic;
`;

export interface ArrayTooltipContentData {
  title: string;
  value: number;
  postfix?: string;
  isTitle?: boolean;
}

interface ArrayTooltipContentProps {
  data: ArrayTooltipContentData[];
}

export const ArrayTooltipContent: React.FC<ArrayTooltipContentProps> = ({ data }) => {
  const isSingle = data.length === 1;

  return (
    <table>
      <tbody>
        {data.map(s => (
          <>
            {(s.isTitle || isSingle) && (
              <HeaderRow border={!isSingle}>
                <td>{s.title}</td>
                <NumberCell>
                  {formatNumber(s.value)} {s.postfix}
                </NumberCell>
              </HeaderRow>
            )}
            {!s.isTitle && !isSingle && (
              <tr key={s.title}>
                <TitleCell>{s.title}</TitleCell>
                <NumberCell>
                  {formatNumber(s.value)} {s.postfix}
                </NumberCell>
              </tr>
            )}
          </>
        ))}
      </tbody>
    </table>
  );
};
